body {
  color: #fff;

  font-family: "Roboto", sans-serif;
}
.signup-form.form {
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0);
  background-color: transparent;
}
.form-control {
  height: 57px;
  box-shadow: none;
}

.form-control,
.btn {
  border-radius: 3px;
}
.signup-form {
  width: 25%;
  margin: auto;
  margin-top: 15rem;
  font-size: 15px;
}
.signup-form h2 {
  color: black;
  margin: 0 0 15px;
  position: relative;
  text-align: center;
}

.signup-form h2:before {
  left: 0;
}
.signup-form h2:after {
  right: 0;
}
.signup-form .hint-text {
  color: #686868;
  margin-bottom: 30px;
  text-align: center;
}
.signup-form form {
  color: rgb(97, 91, 91);
  border-radius: 3px;
  margin-bottom: 15px;
  background: #fff;

  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.3);
  border-radius: 10px 10px 10px 10px;
  padding: 30px;
}
.signup-form .form-group {
  margin-bottom: 20px;
}
.signup-form input[type="checkbox"] {
  margin-top: 3px;
}
.signup-form .btn {
  font-size: 16px;
  font-weight: bold;
  min-width: 140px;
  outline: none !important;
}
.signup-form .row div:first-child {
  padding-right: 10px;
}
.signup-form .row div:last-child {
  padding-left: 10px;
}
.signup-form a {
  color: #fff;
  text-decoration: underline;
}
.signup-form a:hover {
  text-decoration: none;
}
.signup-form form a {
  color: #3ecdd8;
  text-decoration: none;
}
.signup-form form a:hover {
  text-decoration: underline;
}
.text-center {
  color: #0098d5;
}

@media screen and (max-width: 768px) {
  .signup-form {
    width: 60%;
  }
}
@media screen and (max-width: 1024px) {
  .signup-form {
    width: 50%;
  }
}
@media screen and (max-width: 1440px) {
  .signup-form {
    width: 40%;
  }
}
