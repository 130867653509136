.dashboardCard {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 9px;
  min-height: auto;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 0;
  padding-top: 3px;
  padding-bottom: 3px;
}

.ant-card-body {
  padding: 5px;
}
.ant-modal-mask {
  backdrop-filter: blur(4px);
}
